import React from 'react';

export default function Waves(props) {
    return (
        <div className={`${props.position}-waves`}>
            <svg className='waves' viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
                <path className='wave-path' id={`${props.position}-wave`} d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className='parallax'>
                <use href={`#${props.position}-wave`} x="48" y="0" />
                <use href={`#${props.position}-wave`} x="48" y="3" />
                <use href={`#${props.position}-wave`} x="48" y="5" />
                <use href={`#${props.position}-wave`} x="48" y="7" />
              </g>
            </svg>
        </div>
    )
}
// const BaseURLDev = 'http://localhost:1337';
const BaseURL = 'https://strapi-development-9b7a.up.railway.app';
const miInformationAPI = `${BaseURL}/api/my-information?populate=*`;
const works = `${BaseURL}/api/works?populate=*`;
const herotofu = 'https://public.herotofu.com/v1/61c5d830-a586-11ed-a31e-753411848f80';

export {
    miInformationAPI,
    works,
    BaseURL,
    herotofu
}
import { useState, useEffect } from "react";
import axios from 'axios';

const useApiResponse = (url) => {

  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
      const loadWorks = async () => {
          setLoading(true);
          const response = await axios.get(url);
          setApiData(response.data.data);
          setLoading(false);
      }
      loadWorks();
  }, []);

  return { loading, apiData };
}

export default useApiResponse;
import React, { useState, useEffect } from 'react';
import './style.scss';
import MenuIcon from './menuIcon';
import Person2Icon from '@mui/icons-material/Person2';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '../../Hooks/useMediaQuery';

export default function Menu() {
    const isDesktop = useMediaQuery('(min-width: 960px)');
    const [isActive, setIsActive] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className={`menu ${isActive ? 'hover':''}`}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}>
                <div className='item'><a className='menu-option' href="#about">About</a></div>
                <div className='item'><a className='menu-option' href="#works">Work</a></div>
                <div className='item'><a className='menu-option' href="#contact">Contact</a></div>
                <div className="spacer"></div>
                <MenuIcon isHover={isActive}></MenuIcon>
            </div>

            <div className={`menuFixed ${scrollPosition ? '': (isDesktop) ? 'hideMenu' : ''}`}>
                <div className='itemMenu'>
                    <Tooltip title="Home" placement="top">
                        <IconButton className='itemBtn'>
                            <a href="#home"><HomeIcon className='fixedIcon' /></a>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="About" placement="top">
                        <IconButton className='itemBtn'>
                            <a href="#about"><Person2Icon className='fixedIcon' /></a>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Works" placement="top">
                        <IconButton className='itemBtn'>
                            <a href="#works"><HomeRepairServiceIcon className='fixedIcon' /></a>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Contact" placement="top">
                        <IconButton className='itemBtn'>
                            <a href="#contact"><ContactsIcon className='fixedIcon' /></a>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </>
    )
}
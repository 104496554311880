import React from 'react';
import './style.scss';
import vuejs from '../../assets/img/logos/vue.png';
import html5 from '../../assets/img/logos/html5.png';
import css3 from '../../assets/img/logos/css3.png';
import sass from '../../assets/img/logos/sass.png';
import reactjs from '../../assets/img/logos/react.png';
import salesforce from '../../assets/img/logos/salesforce.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function AboutTips () {
    return (
        <div className='logos-list max-container-size'>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={vuejs} alt="" className='logo' />
                    </Grid>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={html5} alt="" className='logo' />
                    </Grid>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={css3} alt="" className='logo' />
                    </Grid>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={sass} alt="" className='logo' />
                    </Grid>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={reactjs} alt="" className='logo' />
                    </Grid>
                    <Grid xs={4} md={3} lg={2}>
                        <img src={salesforce} alt="" className='logo' />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
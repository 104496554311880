import { useState, useEffect } from 'react';
import axios from 'axios';
import './style.scss';
// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Titles from '../general/titles';
// import Button from '@mui/material/Button';
import { works } from '../../Services';
// import Skeleton from '@mui/material/Skeleton';

export default function Portfolio() {
  const [loading, setLoading] = useState(true);
  const [listwork, setListWork] = useState(null);
  

  useEffect(() => {
      const loadWorks = async () => {
          setLoading(true);
          const response = await axios.get(works);
          setListWork(response.data.data);
          setLoading(false);
      }
      loadWorks();
  }, []);

  return (
    <div className='max-container-size' id='works'>
      <Titles title="My last projects" />
      { loading ? 
      (
        // <Box sx={{ flexGrow: 1 , p: 4}}>
        //   <Grid container justifyContent="center" alignItems="center">
        //     {[...Array(3)].map((i,idx) => {
        //       return (
        //       <Grid container item justifyContent="center"  xs={12} sm={6} md={4} key={idx}>
        //         <Skeleton sx={itemSkeleton} />
        //       </Grid>
        //       )
        //     })}
        //   </Grid>
        // </Box>
        <Grid container justifyContent="center" spacing={4} alignItems="center">
          {
          listworkLocal.map(work => (
              <Grid container item justifyContent="center" xs={12} sm={6} md={4} key={work.id}>
                <span className='portfolio-card' style={{backgroundImage: `url("${work.img}")`}}>
                  <div className="overlay"></div>
                  <h3>{work.title}</h3>
                  <p>{work.description}</p>
                  {/* <BootstrapButton className='claymorphism-btn' color="primary">Look</BootstrapButton> */}
                  <a className='btn claymorphism-btn' href={work.url}>Look</a>
                </span>
              </Grid>
          ))
          }
        </Grid>
          
      ) : 
      (
        <Grid container justifyContent="center" alignItems="center">
          {
          listwork.map(work => (
              <Grid container item justifyContent="center"  xs={12} sm={6} md={4} key={work.attributes.title}>
                <span className='portfolio-card' style={{backgroundImage: `url("${work.attributes.img.data.attributes.formats.small.url}")`}}>
                  <div className="overlay"></div>
                  <h3>{work.attributes.title}</h3>
                  <p>{work.attributes.description}</p>
                  {/* <BootstrapButton className='claymorphism-btn' color="primary">Look</BootstrapButton> */}
                </span>
              </Grid>
          ))
          }
        </Grid>
      )}
    </div>
  );
}


const listworkLocal = [
  {
    id: 1,
    title: 'Asbanc',
    description: 'E-commerce build it with Nuxt JS and PHP in the backend with laravel',
    img: 'portfolio/asbanc.jpg',
    url: 'asbanc.com.pe'
  },
  {
    id: 2,
    title: 'Movistar - catalogo',
    description: 'Working with movistar catalog in Wunderman Thompson Agency like a front-end developer using JS Vanilla in cases, Jquery,  HTML5 and css3',
    img: 'portfolio/catalogo-movistar.jpg',
    url: 'https://catalogo.movistar.com.pe/'
  },
  {
    id: 3,
    title: 'Confianza',
    description: 'Website build it in PHP with Mysql, html5 with css3 on the frontend side with their own backend.',
    img: 'portfolio/confianza.jpg',
    url: 'https://confianza.pe/'
  },
  {
    id: 4,
    title: 'El palacio de hierro',
    description: 'E-commerce build it with salesforce commerce cloud.',
    img: 'portfolio/eph.jpg',
    url: 'https://elpalaciodehierro.com/'
  },
  {
    id: 5,
    title: 'Izipay - compraya',
    description: 'Checkout system build it with python in the backend and Vuejs 2 in the frontend.',
    img: 'portfolio/izipay.jpg',
    url: 'https://compraya.izipay.pe/comprar/cliente/'
  },
  {
    id: 6,
    title: 'Vertical',
    description: 'E-commerce build it with Nuxt JS and PHP in the backend with laravel',
    img: 'portfolio/vertical.jpg',
    url: 'https://vertical.pe/'
  },
  {
    id: 7,
    title: 'Facturation System',
    description: 'facturation system build it in PHP with Mysql in the back side and html5 with css3 on the frontend side.',
    img: 'portfolio/factoring-system-jsj.jpg',
    url: 'https://jsjconsultores.com/',
  },
  {
    id: 8,
    title: 'Elemental',
    description: 'Landing page build it with html5 with css3 and Javascript with EmailJS to send emails.',
    img: 'portfolio/ess.jpg',
    url: 'https://ess.pe/',
  },
  {
    id: 9,
    title: 'IZIVendo',
    description: 'App build it with Nuxt JS to build cards presentation where you can add information to your cards, like the name, phone and coupon code to sell',
    img: 'portfolio/izivendo.jpg',
    url: 'https://www.izivendo.pe/',
  },
  
]

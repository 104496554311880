import './style.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import image1 from "../../assets/img/hands/hand_hello.png";
import image2 from "../../assets/img/hands/hand_rock.png";
import image3 from "../../assets/img/hands/hand_like.png";
import image4 from "../../assets/img/hands/hand_phone.png";
import image5 from "../../assets/img/hands/hand_pointer.png";
import likeHand from "../../assets/img/hands/hand_thumb_down.png";
import hiHand from "../../assets/img/hands/hand_open.png";
import { miInformationAPI } from '../../Services';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useApiResponse from '../../Hooks/useAPI';

export default function Banner() {
    const { loading, apiData } = useApiResponse(miInformationAPI);

    return (
        <div className='TopBanner' id='home'>
            <div className='hands hands_right_top'>
                <img src={image2} className="no-hover" alt="rock" />
                <img src={likeHand} className="hover" alt="rock" />
                <img src={hiHand} className="hi" alt="rock" />
            </div>
            <div className='TopBanner_floating_bg'>
                <div className='TopBanner_floating'>
                    <img className='yFloat-1' src={image1} alt="image1" />
                    <img className='yFloat-2' src={image2} alt="image2" />
                    <img className='yFloat-3' src={image3} alt="image3" />
                    <img className='yFloat-4' src={image4} alt="image4" />
                    <img className='yFloat-5' src={image5} alt="image5" />
                </div>
            </div>
            <div className='TopBanner_row'>
                <div className='TopBanner_content'>
                    <h1 className='TopBanner_content-title'>Hi,</h1>
                    {loading ? (
                            <Stack spacing={1}>
                                {/* <Skeleton variant="text" sx={{ fontSize: '3rem', bgcolor: 'gray' }} />
                                <Skeleton variant="text" sx={{ fontSize: '3rem', bgcolor: '#00ADB5' }} />
                                <Skeleton variant="rectangular"  sx={{ bgcolor: 'gray' }} width={300} height={60} /> */}
                                <h1 className='TopBanner_content-title'>{dataLocal.name}</h1>
                                <h1 className='TopBanner_content-subtitle'>{dataLocal.position}</h1>
                                <span className='TopBanner_content-short'>{dataLocal.description}</span>
                            </Stack>
                        ) : (
                            <div>
                                <h1 className='TopBanner_content-title'>I'm {apiData.attributes.name}</h1>
                                <h1 className='TopBanner_content-subtitle'>{apiData.attributes.position}</h1>
                                <span className='TopBanner_content-short'>{apiData.attributes.description}</span>
                                
                                <Box sx={{ flexGrow: 1, pt: 3 }}>
                                    <a href={apiData.attributes.CV.data.attributes.url} className='noAnchor' download>
                                        <Button variant="contained" className='bgBtn claymorphism-btn'><FileDownloadIcon />Look my CV</Button>
                                    </a>    
                                </Box>
                            </div>
                        )
                    }
                    
                </div>
            </div>
        </div>
    )
}


const dataLocal = {
    name: 'Larry García',
    position: 'Front-end Developer',
    description:'Development of web sites and applications, as well as cross-platform mobile applications and salesforce Ecommerce Cloud Developer.'
}
import React from 'react';
import Typography from '@mui/material/Typography';

const styled = {
    textAlign:'center',
    color:'white',
    padding: '20px 0'
}

export default function Titles(props) {
    return (
        <div className='titles' style={styled}>
            <Typography variant="span">
                {props.subtitle}
            </Typography>
            <Typography variant="h3" gutterBottom>
                {props.title}
            </Typography>
        </div>
    )
}
import './assets/scss/all.scss';
import React from 'react';
import Menu from './components/Menu';
import Banner from './components/Banner';
import About from './components/About';
import AboutTips from './components/AboutTips';
import Portfolio from './components/Portfolio';
import ContactMe from './components/ContactMe';
import Foot from './components/Foot/Index';
import Waves from './components/general/waves';


function App() {

  return (
    <div className="App">
      <header className="Portfolio-body">
        <Menu></Menu>
        <Banner></Banner>
        <div className='after-banner'>
          <Waves position="top"></Waves>
          <About></About>
          <AboutTips></AboutTips>
          <Portfolio></Portfolio>
          <ContactMe></ContactMe>
          <Foot></Foot>
          <Waves position="bottom"></Waves>
        </div>
      </header>
    </div>
  );
}

export default App;
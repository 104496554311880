import './style.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Titles from '../general/titles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { miInformationAPI } from '../../Services';
import useApiResponse from '../../Hooks/useAPI';
import AIME from '../../assets/img/AI-ME.jpg';


export default function Menu() {
    const { loading, apiData } = useApiResponse(miInformationAPI);
    return (
        <>
        <div className='About' id='about'>
            <Titles title="About me" />
            <Container maxWidth="lg">
                <Box sx={{ flexGrow: 1 }}>
                    { loading ? 
                    (
                        <Grid container>
                            <Grid xs={12} md={6}>
                                {/* <Skeleton sx={{ bgcolor: 'gray' }} variant="rounded" width={400} height={400} /> */}
                                <img className='About_img' src={AIME} alt="" />
                            </Grid>
                            <Grid xs={12} md={6}>
                                {/* <Box sx={{ width: '100%' }}>
                                    <Stack spacing={1}>
                                        <Skeleton sx={{ bgcolor: 'gray' }} />
                                        <Skeleton sx={{ bgcolor: 'gray' }} />
                                        <Skeleton sx={{ bgcolor: 'gray' }} />
                                        <Skeleton sx={{ bgcolor: 'gray' }} />
                                        <Skeleton sx={{ bgcolor: 'gray' }} />
                                        <Skeleton sx={{ bgcolor: '#00ADB5' }} variant="rounded" width={80} height={40} />
                                    </Stack>
                                </Box> */}
                                <span className='About_content-short'>
                                    {aboutData.about}
                                </span>
                            </Grid>
                        </Grid>
                    ) : 
                    (
                        <Grid container>
                            <Grid xs={12} md={6}>
                                <img className='About_img' src={apiData.attributes.Image.data.attributes.formats.small.url} alt="" />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <span className='About_content-short'>
                                {apiData && apiData.attributes.about}
                                </span>
                                <Box sx={{ flexGrow: 1, pt: 3 }}>
                                    <a className='noAnchor' href="#contact">
                                        <Button variant="contained" className='bgBtn claymorphism-btn'><ChatBubbleIcon /> Let's Talk</Button>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    
                </Box>
            </Container>
        </div>
        </>
        
    )
}


const aboutData = {
    about:"Hello! My name is Larry García and I enjoy creating things that live on the internet. My interest in web development started back in 2016 when I decided to try create a landing pages for a customer. That's experience taught me a lot about HTML, CSS & JS! Fast-forward to today, and I’ve had the privilege of working at an advertising agency, a start-up, and a huge corporation. My main focus these days is building accessible, inclusive products and digital experiences at Upstatement for a variety of clients.",
}

import React, { useState } from 'react';
import './style.scss';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Formik, Form, Field } from 'formik';
import UseEmail from "../../Hooks/useEmail";
import { herotofu } from "../../Services";
import * as Yup from 'yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SendSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  message: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default function ContactMe() {
  const [formItsOk, setFormItsOk] = useState(true);
  
  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
  }
  
  const {
    loader,
    submitted,
    sendEmail
  } = UseEmail(herotofu);

  function handleSubmit(values) {
    sendEmail({
      name: values.name,
      email: values.email,
      message: values.message,
    });
  }
  
  return (
    <Box id="contact" className='max-container-size contact' sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={6}>
            <div className='highlight-letter'>
                <h3>Let's <span><ArrowForwardIcon /></span> Connect</h3>
            </div>
            { formItsOk ? (
              <div className='box-info'>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <EmailOutlinedIcon className='iconic' />
                      <span>Email</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="mailto:larsgpx@gmail.com">Larsgpx@gmail.com</a>
                    </div>
                </div>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <WhatsAppIcon className='iconic' />
                      <span>Write me</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="tel:+51955549584">Whatsapp</a>
                    </div>
                </div>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <SmartphoneIcon className='iconic' />
                      <span >Phone</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="tel:+51955549584">(+51) 955 549 584</a>
                    </div>
                </div>
            </div>
            ) : ('')}
           
        </Grid>
        <Grid xs={12} sm={6}>
            { formItsOk ? (
               <div className='formContact'>
               <Formik
               initialValues={{
                   name: '',
                   email: '',
                   message: '',
               }}
               validationSchema={SendSchema}
               onSubmit={(values, actions) => {
                  handleSubmit(values);
                  actions.resetForm({
                    values: {
                      name: '',
                      email: '',
                      message: '',
                    }
                  });
               }}
               >
               {({ errors, touched, dirty }) => (
                   <Form>
                       <label for="name">Your name:</label>
                       <Field name="name" />
                       {errors.name && touched.name ? (
                           <div className='error-msg'>{errors.name}</div>
                       ) : null}
                       <label for="name">Your email:</label>
                       <Field name="email" type="email" />
                       {errors.email && touched.email ? <div className='error-msg'>{errors.email}</div> : null}

                       <label for="name">Tell about the project:</label>
                       <Field name="message" as="textarea" />
                       {errors.message && touched.message ? (
                           <div className='error-msg'>{errors.message}</div>
                       ) : null}
                       <LoadingButton
                       sx={{mt: 2, mb: 2}}
                       endIcon={(dirty && !isObjectEmpty(errors)) ? <ErrorIcon/> : <SendIcon />}
                       loading={loader}
                       loadingPosition="end"
                       variant="contained"
                       className={`Btn-send claymorphism-btn ${(dirty && !isObjectEmpty(errors)) ? 'm-error' : ''}`}
                       type="submit"
                       disabled={!isObjectEmpty(errors) || !dirty}
                       >
                       Send 
                       </LoadingButton>
                       <br />
                   </Form>
               )}
               </Formik>
           </div>
            ) : 
            (
              <div className='box-info m-no_form'>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <EmailOutlinedIcon className='iconic' />
                      <span>Email</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="mailto:larsgpx@gmail.com">Larsgpx@gmail.com</a>
                    </div>
                </div>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <WhatsAppIcon className='iconic' />
                      <span>Write me</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="tel:+51955549584">Whatsapp</a>
                    </div>
                </div>
                <div className="box-info_mini">
                    <div className='box-info_mini_front'>
                      <SmartphoneIcon className='iconic' />
                      <span >Phone</span>
                    </div>
                    <div className='box-info_mini_back'>
                      <a href="tel:+51955549584">(+51) 955 549 584</a>
                    </div>
                </div>
              </div>
            )
          }
        </Grid>
      </Grid>
      <Snackbar open={submitted} autoHideDuration={5000}>
        <Alert severity="success" sx={{ width: '100%' }}>
        Message send, I'll be contact you soon!
        </Alert>
      </Snackbar>
    </Box>
  );
}

import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import { miInformationAPI } from '../../Services';
import useApiResponse from '../../Hooks/useAPI';
import Skeleton from '@mui/material/Skeleton';

const styled = {
    textAlign:'center',
    color:'white',
    backgroundColor: 'rgb(19, 24, 33)',
    width: '100%',
    zIndex: '5'

}

export default function Foot() {
    const { loading, apiData } = useApiResponse(miInformationAPI);
    
    return (
        <div className='footer' style={styled}>
            <div className='footer_developed'>
                <p>Developed by <CodeIcon /> Larry García</p>
            </div>
            <div className='footer_socialmedia'>
                {/* {loading && <Skeleton sx={{ bgcolor: 'gray' }} />} */}
                {loading && (
                    <>
                        <a href={footData.twitter} target="blank"><TwitterIcon /></a>
                        <a href={footData.instagram} target="blank"><InstagramIcon /></a>
                        <a href={footData.facebook} target="blank"><FacebookIcon /></a>
                        <a href={footData.github} target="blank"><GitHubIcon /></a>
                        <a href={footData.linkedin} target="blank"><LinkedInIcon /></a>
                    </>
                )}

                {!loading && apiData && (
                    <>
                        <a href={apiData.attributes.socialmedia[1] ? apiData.attributes.socialmedia[1].url : '#'} target="blank"><TwitterIcon /></a>
                        <a href={apiData.attributes.socialmedia[0] ? apiData.attributes.socialmedia[0].url : '#'} target="blank"><InstagramIcon /></a>
                        <a href={apiData.attributes.socialmedia[2] ? apiData.attributes.socialmedia[2].url : '#'} target="blank"><FacebookIcon /></a>
                        <a href={apiData.attributes.socialmedia[3] ? apiData.attributes.socialmedia[3].url : '#'} target="blank"><GitHubIcon /></a>
                        <a href={apiData.attributes.socialmedia[4] ? apiData.attributes.socialmedia[4].url : '#'} target="blank"><LinkedInIcon /></a>
                        {/* <a href="#" target="blank"><LinkedInIcon /></a> */}
                    </>
                )}
            </div>

        </div>
    )
}

const footData = {
    twitter: 'https://twitter.com/larsgp',
    instagram: 'https://www.instagram.com/larsgpx',
    facebook: 'https://www.facebook.com/larryjoel.garciaparedes',
    github: 'https://github.com/larsgpx',
    linkedin: 'https://www.linkedin.com/in/larry-garcia-818646aa/'
}